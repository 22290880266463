import { Controller } from "@hotwired/stimulus"
import 'select2';

var selected_data = {};

export default class extends Controller {
  static targets = [ 'copyButton', 'collapsible' ]
  static values = {}

  connect() {
    console.log("Hello, Admin Drift Detection Controller here!", this.element);

    if (window.location.hash) {
      // open the model you were previously looking at
      const target = window.location.hash.substr(1);

      this.collapsibleTargets.forEach(elm => elm.style.display = 'none');
      const element = this.collapsibleTargets.find(elm => elm.id === `collapsible-${target}`);
      element.style.display = 'block';
    }
  }

  toggleCollapsible(event) {
    console.log("toggleCollapsible", event.target);

    const target = event.params.target;

    const element = this.collapsibleTargets.find(elm => elm.id === `collapsible-${target}`);
    if(element.style.display == 'block') {
      element.style.display = 'none';
    } else {
      element.style.display = 'block';
    }

    window.location.hash = target;
  }

  copyToEnv(event) {
    // model, id, value
    debugger;
  }

}
