import { Controller } from "@hotwired/stimulus"
import { createAlert, clearAlerts } from '../packs/createAlert';

let intervalId;

export default class extends Controller {
  static targets = [ 'transcript' ]
  static values = {
    teamId: Number,
    gameId: Number,
    quotes: Array
  }

  connect() {
    // console.log("Hello, Stimulus from Game recap Quotes controller!", this.element);
    // console.log(this.urlValue);
    // console.log(this.transcriptTarget);
    // console.log(this.teamIdValue);
    // console.log(this.gameIdValue);
    // console.log(this.quotesValue);

    // if we are waiting for transcription to be ready, we will poll the server every 5 seconds
    if (!this.hasTranscriptTarget) {
      // console.log('No transcript target, fetching transcript');
      intervalId = setInterval( () => this.fetchTranscription(), 5000);
    }

    if (this.hasTranscriptTarget) {
      this.transcriptTarget.addEventListener('mouseup', (event) => {
        // console.log('mouseup');
        this.saveQuote();
      });

      this.transcriptTarget.addEventListener('click', (event) => {
        // console.log('click');

        if (!event.target.dataset['quoteId']) {
          return;
        }

        this.deleteQuote(event);
      });
    }

    // initialize the existing quotes on the page
    if (this.quotesValue.length > 0) {
      this.displayQuotes();
    }

  }

  fetchTranscription() {
    try {
      fetch(`/entity/teams/${this.teamIdValue}/games/${this.gameIdValue}/transcription_results`).then(results => {
        if (results.status === 200) {
          clearInterval(intervalId);
          window.location.reload();
        }
      });
    } catch (e) {
      console.error('Error when trying to fetch transcription: ', e);
    }
  }

  saveQuote() {
    let selection = null;
    if (window.getSelection) {
      selection = window.getSelection();
    }

    if (document.selection) {
      selection = document.selection;
    }

    const text = selection.toString().trim();

    if (!text || text === " ") {
      return
    }

    // if (selection.rangeCount > 0) {
      // const range = selection.getRangeAt(0);
      // const startOffset = range.startOffset;
      // const endOffset = range.endOffset;

      // console.table({
      //   text,
      //   startOffset,
      //   endOffset
      // });

      fetch('/quotes.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          quote: {
            text,
            quoteable_type: 'Entity::Game',
            quoteable_id: this.gameIdValue
            // parameters: {
            //   start_offset: startOffset,
            //   end_offset: endOffset
            // }
          }
        })
      })
      .then(resp => resp.json())
      .then(data => {
        // console.log("Quotes", data);

        if (data.quotes && data.quotes.length > 1) {
          clearAlerts();

          createAlert('success', 'Quote saved');

          this.quotesValue = data.quotes;
          this.displayQuotes();
        } else if (data.text) {
          createAlert('danger', 'Quote must be longer than 4 words');
        }

      })

    // }

  }

  displayQuotes() {
    // console.log(this.quotesValue);

    this.quotesValue.forEach(quote => {

      const newText = this.transcriptTarget.innerHTML.replace(quote.text, `<span class="quote" data-quote-id='${quote.id}'>${quote.text}</span>`);
      this.transcriptTarget.innerHTML = newText;

    });
  }

  deleteQuote(event) {
    const quoteId = event.target.dataset['quoteId'];
    fetch(`/quotes/${quoteId}.json`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(resp => resp.json())
    .then(data => {
      this.quotesValue = data.quotes;

      // remove all of the quotes from UI so they can be re-added
      let doc = new DOMParser().parseFromString(this.transcriptTarget.innerHTML, 'text/html');
      this.transcriptTarget.innerHTML = doc.body.textContent || "";

      this.displayQuotes();

      createAlert('success', 'Quote removed');
    });
  }


}

